var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var VI,XI,ZI,kJ,oJ,wJ;$CLJS.UI=new $CLJS.M(null,"get-month","get-month",-369374731);VI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.WI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);XI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.YI=new $CLJS.M(null,"iso","iso",-1366207543);ZI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.$I=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.aJ=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.bJ=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.cJ=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.dJ=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.eJ=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.fJ=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.gJ=new $CLJS.M(null,"us","us",746429226);$CLJS.hJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.iJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.jJ=new $CLJS.M(null,"now","now",-1650525531);kJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.lJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.mJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.nJ=new $CLJS.M(null,"get-week","get-week",-888059349);
oJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.pJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.qJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.rJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.sJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.tJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.uJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.vJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);wJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.xJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.yJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.zJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.AJ=new $CLJS.M(null,"target","target",253001721);$CLJS.EF($CLJS.fJ,$CLJS.H([$CLJS.ot,$CLJS.iD,$CLJS.Hs,$CLJS.mE]));$CLJS.TE.m(null,$CLJS.cJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.UE(a);return $CLJS.vd(a)?(a=$CLJS.lu.g(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.YC,null,$CLJS.xD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.xF($CLJS.cJ,$CLJS.jF);
for(var BJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pJ,$CLJS.vJ],null)),CJ=null,DJ=0,EJ=0;;)if(EJ<DJ){var FJ=CJ.X(null,EJ);$CLJS.EF(FJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mE],null)]));$CLJS.xF(FJ,$CLJS.cJ);EJ+=1}else{var GJ=$CLJS.y(BJ);if(GJ){var HJ=GJ;if($CLJS.Ad(HJ)){var IJ=$CLJS.lc(HJ),Nga=$CLJS.mc(HJ),Oga=IJ,Pga=$CLJS.D(IJ);BJ=Nga;CJ=Oga;DJ=Pga}else{var JJ=$CLJS.A(HJ);$CLJS.EF(JJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.bF],null),$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mE],null)]));$CLJS.xF(JJ,$CLJS.cJ);BJ=$CLJS.B(HJ);CJ=null;DJ=0}EJ=0}else break}
for(var KJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.aJ,$CLJS.UI,$CLJS.mJ,$CLJS.uJ,$CLJS.qJ,$CLJS.$I,$CLJS.xJ],null)),LJ=null,MJ=0,NJ=0;;)if(NJ<MJ){var Qga=LJ.X(null,NJ);$CLJS.EF(Qga,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));NJ+=1}else{var OJ=$CLJS.y(KJ);if(OJ){var PJ=OJ;if($CLJS.Ad(PJ)){var QJ=$CLJS.lc(PJ),Rga=$CLJS.mc(PJ),Sga=QJ,Tga=$CLJS.D(QJ);KJ=Rga;LJ=Sga;MJ=Tga}else{var Uga=$CLJS.A(PJ);$CLJS.EF(Uga,$CLJS.H([$CLJS.ot,
$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));KJ=$CLJS.B(PJ);LJ=null;MJ=0}NJ=0}else break}$CLJS.EF($CLJS.eJ,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hH],null)]));
for(var RJ=$CLJS.y(new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.xJ,null,$CLJS.qJ,null,$CLJS.uJ,null,$CLJS.rJ,null,$CLJS.$I,null,$CLJS.UI,null,$CLJS.aJ,null,$CLJS.mJ,null],null),null)),SJ=null,TJ=0,UJ=0;;)if(UJ<TJ){var Vga=SJ.X(null,UJ);$CLJS.EF(Vga,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));UJ+=1}else{var VJ=$CLJS.y(RJ);if(VJ){var WJ=VJ;if($CLJS.Ad(WJ)){var XJ=$CLJS.lc(WJ),Wga=$CLJS.mc(WJ),Xga=XJ,Yga=$CLJS.D(XJ);
RJ=Wga;SJ=Xga;TJ=Yga}else{var Zga=$CLJS.A(WJ);$CLJS.EF(Zga,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));RJ=$CLJS.B(WJ);SJ=null;TJ=0}UJ=0}else break}$CLJS.X(wJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,$CLJS.YI,$CLJS.gJ,$CLJS.lJ],null));
$CLJS.GF($CLJS.nJ,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.sJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.zE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,2,[$CLJS.vt,"valid timezone ID",$CLJS.xt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Cj);return["invalid timezone ID: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.bH($CLJS.Jd,$CLJS.TI.tz.names())),$CLJS.KI],null)],null));
$CLJS.GF($CLJS.WI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sJ],null)],null)],null)],
null)]));$CLJS.xF($CLJS.WI,$CLJS.cJ);$CLJS.EF($CLJS.jJ,$CLJS.H([$CLJS.ot,$CLJS.aD]));$CLJS.X(ZI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.vt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Re(function(b){return $CLJS.xC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YC,$CLJS.xD],null))}],null)],null));
$CLJS.X(VI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wB,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZI],null)],null)],null)],null));
$CLJS.QG.g($CLJS.bJ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.vt,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.bJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.vt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.II],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.vt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.Yv],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eH],null)],null)],null)],null)],null)],null));
$CLJS.TE.m(null,$CLJS.bJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Yv)?$CLJS.E.g(a,$CLJS.ci)?$CLJS.xD:$CLJS.n($CLJS.wF($CLJS.iH,a))?$CLJS.YC:$CLJS.xD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.nh($CLJS.FI,b))?$CLJS.YC:$CLJS.n($CLJS.nh($CLJS.GI,b))?$CLJS.YC:null:null;if($CLJS.n(a))return a;b=$CLJS.UE(b);b=$CLJS.vd(b)?$CLJS.eg.j($CLJS.bh,$CLJS.kf(function(c){return $CLJS.xC(c,$CLJS.Uj)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(oJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.Yv],null),$CLJS.Hs],null));
$CLJS.GF($CLJS.hJ,$CLJS.H([$CLJS.ot,$CLJS.xD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mE],null)],null)],null)],null)]));
$CLJS.EF($CLJS.Ky,$CLJS.H([$CLJS.ot,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null)]));$CLJS.X(XI,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ss,$CLJS.gH,$CLJS.zi,$CLJS.kk,$CLJS.dJ,$CLJS.yJ,$CLJS.zJ,$CLJS.Ej,$CLJS.Fi,$CLJS.vi,$CLJS.hj,$CLJS.dH],null));$CLJS.X(kJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,$CLJS.YI,$CLJS.gJ,$CLJS.lJ],null));
$CLJS.GF($CLJS.tJ,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kJ],null)],null)],
null)],null)]));