var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var n4,p4,q4,r4,Opa;n4=function(a,b,c){var d=$CLJS.MW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.eG);d=$CLJS.BH($CLJS.Nk.j($CLJS.ch([c]),$CLJS.GE,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.hi($CLJS.pE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.Ks,c,$CLJS.MF,a,$CLJS.JL,b],null));return d};$CLJS.o4=function(a){return $CLJS.h2($CLJS.CG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
p4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);q4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);r4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Opa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.L0.m(null,$CLJS.eG,function(a,b){var c=$CLJS.Fe($CLJS.eG.h($CLJS.MW(a,b)));return $CLJS.n(c)?$CLJS.j2($CLJS.RE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.i0.v(a,b,v,$CLJS.j0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.i0.v(a,
b,t,$CLJS.j0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.O0.m(null,$CLJS.eG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.wB);e=$CLJS.J.g(e,$CLJS.Ei);c=$CLJS.I(c,2,null);c=n4(a,b,c);return $CLJS.el.l($CLJS.H([$CLJS.h0.j(a,b,c),new $CLJS.h(null,2,[$CLJS.VK,$CLJS.nL,$CLJS.l1,$CLJS.GE.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.wB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Ei,e],null):null]))});
$CLJS.I0.m(null,$CLJS.eG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.i0.v(a,b,n4(a,b,c),d)});$CLJS.xF(q4,r4);
for(var s4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wx,$CLJS.qG],null)),t4=null,u4=0,v4=0;;)if(v4<u4){var Ppa=t4.X(null,v4);$CLJS.xF(Ppa,q4);v4+=1}else{var w4=$CLJS.y(s4);if(w4){var x4=w4;if($CLJS.Ad(x4)){var y4=$CLJS.lc(x4),Qpa=$CLJS.mc(x4),Rpa=y4,Spa=$CLJS.D(y4);s4=Qpa;t4=Rpa;u4=Spa}else{var Tpa=$CLJS.A(x4);$CLJS.xF(Tpa,q4);s4=$CLJS.B(x4);t4=null;u4=0}v4=0}else break}
$CLJS.I0.m(null,q4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.i0.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.pE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.pE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.RE("Count");case "cum-count":return $CLJS.RE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.J0.m(null,q4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.O0.m(null,q4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.uI($CLJS.O0,r4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Tj,$CLJS.ZC)});$CLJS.I0.m(null,$CLJS.ZF,function(){return $CLJS.RE("Case")});
$CLJS.J0.m(null,$CLJS.ZF,function(){return"case"});$CLJS.xF(p4,r4);for(var z4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.jG,$CLJS.MG,$CLJS.VF,$CLJS.hk,$CLJS.DG,$CLJS.Xn,$CLJS.FG,$CLJS.CG,$CLJS.WF],null)),A4=null,B4=0,C4=0;;)if(C4<B4){var Upa=A4.X(null,C4);$CLJS.xF(Upa,p4);C4+=1}else{var D4=$CLJS.y(z4);if(D4){var E4=D4;if($CLJS.Ad(E4)){var F4=$CLJS.lc(E4),Vpa=$CLJS.mc(E4),Wpa=F4,Xpa=$CLJS.D(F4);z4=Vpa;A4=Wpa;B4=Xpa}else{var Ypa=$CLJS.A(E4);$CLJS.xF(Ypa,p4);z4=$CLJS.B(E4);A4=null;B4=0}C4=0}else break}
$CLJS.J0.m(null,p4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.I0.m(null,p4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.i0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.pE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.pE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.pE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.pE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.pE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.pE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.pE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.pE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.pE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.I0.m(null,$CLJS.aG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.pE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.i0.v(a,b,e,d)]))});$CLJS.J0.m(null,$CLJS.aG,function(){return"percentile"});
$CLJS.xF($CLJS.aG,r4);$CLJS.I0.m(null,$CLJS.KG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.pE("Sum of {0} matching condition",$CLJS.H([$CLJS.i0.v(a,b,e,d)]))});$CLJS.J0.m(null,$CLJS.KG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.K0.j(a,b,d))].join("")});$CLJS.xF($CLJS.KG,r4);$CLJS.I0.m(null,$CLJS.wG,function(){return $CLJS.RE("Share of rows matching condition")});
$CLJS.J0.m(null,$CLJS.wG,function(){return"share"});$CLJS.xF($CLJS.wG,r4);$CLJS.I0.m(null,$CLJS.yG,function(){return $CLJS.RE("Count of rows matching condition")});$CLJS.J0.m(null,$CLJS.yG,function(){return"count-where"});$CLJS.xF($CLJS.yG,r4);
$CLJS.O0.m(null,r4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.el.l;d=$CLJS.n(d)?$CLJS.cm($CLJS.h0.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gL],null)):null;var f=$CLJS.uI($CLJS.O0,$CLJS.ci);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.el,$CLJS.H([d,a]))});
$CLJS.Zpa=function(){function a(d){return $CLJS.h2($CLJS.Wx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.h2($CLJS.Wx,$CLJS.Cf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.vW.m(null,$CLJS.eG,function(a){return a});
$CLJS.G4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.jC(f),$CLJS.ZK))f=$CLJS.xW(f);else return $CLJS.k2(d,e,$CLJS.eG,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.H4=function(){function a(d,e){return $CLJS.Fe($CLJS.eG.h($CLJS.MW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.I4=function(){function a(d,e){var f=$CLJS.Fe($CLJS.eG.h($CLJS.MW(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(k){var l=$CLJS.h0.j(d,e,k),m=$CLJS.R.l,t=$CLJS.wB.h(l);return m.call($CLJS.R,$CLJS.g2(l,$CLJS.Ei,$CLJS.n(t)?t:$CLJS.zj),$CLJS.VK,$CLJS.nL,$CLJS.H([$CLJS.l1,$CLJS.GE.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.I0.m(null,$CLJS.ZG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.VG);return $CLJS.uE.h(a.o?a.o():a.call(null))});$CLJS.P0.m(null,$CLJS.ZG,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.iG);b=$CLJS.J.g(d,$CLJS.VG);c=$CLJS.J.g(d,$CLJS.TG);d=$CLJS.J.g(d,$CLJS.UZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.G0,$CLJS.bB(a),$CLJS.H([$CLJS.E0,c]));return null!=d?$CLJS.R.j(a,$CLJS.q0,d):a});
$CLJS.$pa=function(){function a(d,e){var f=function(){var m=$CLJS.kA.h($CLJS.C1($CLJS.b0(d)));return $CLJS.n(m)?m:$CLJS.bh}(),k=$CLJS.MW(d,e),l=$CLJS.T0.j(d,e,k);return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Nk.j($CLJS.kf(function(m){m=$CLJS.XG.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Vl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.TG),u=$CLJS.J.g(m,$CLJS.RG);if($CLJS.Va(t))return m;if($CLJS.E.g(u,$CLJS.Ms))return $CLJS.R.j(m,$CLJS.JW,l);t=$CLJS.Fe($CLJS.g1(function(v){return $CLJS.E_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.JW,t):null}),$CLJS.hf.h(function(m){return $CLJS.R.j(m,$CLJS.cu,$CLJS.ZG)})),$CLJS.$G))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.aqa=function(){function a(d,e){return $CLJS.wW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG.h(d),$CLJS.N,$CLJS.yW.h(e)],null))}function b(d){if($CLJS.Va($CLJS.TG.h(d)))return $CLJS.wW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iG.h(d),$CLJS.N],null));var e=$CLJS.iG.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.hi(e,new $CLJS.h(null,1,[Opa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();