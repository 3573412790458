var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.types.isa.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var u2=function(a,b){a=a instanceof $CLJS.M?a.T:null;switch(a){case "\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "equal-to":return $CLJS.RE("Equal to");case "default":return $CLJS.RE("Is");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "!\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-equal-to":return $CLJS.RE("Not equal to");case "excludes":return $CLJS.RE("Excludes");case "default":return $CLJS.RE("Is not");default:throw Error(["No matching clause: ",
$CLJS.p.h(b)].join(""));}case "\x3e":switch(b=b instanceof $CLJS.M?b.T:null,b){case "after":return $CLJS.RE("After");case "default":return $CLJS.RE("Greater than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c":switch(b=b instanceof $CLJS.M?b.T:null,b){case "before":return $CLJS.RE("Before");case "default":return $CLJS.RE("Less than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3e\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.RE("Greater than or equal to");
default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.RE("Less than or equal to");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "between":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.RE("Between");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "is-empty":return $CLJS.RE("Is empty");
case "default":return $CLJS.RE("Is null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-empty":return $CLJS.RE("Not empty");case "default":return $CLJS.RE("Not null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-empty":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.RE("Is empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-empty":switch(b=
b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.RE("Not empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "contains":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.RE("Contains");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "does-not-contain":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.RE("Does not contain");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}case "starts-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.RE("Starts with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "ends-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.RE("Ends with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "inside":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.RE("Inside");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},v2=function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "\x3d":return"\x3d";case "!\x3d":return"≠";case "\x3e":return"\x3e";case "\x3c":return"\x3c";case "\x3e\x3d":return"≥";case "\x3c\x3d":return"≤";default:return u2(a,b)}},ooa=new $CLJS.M(null,"after","after",594996914),poa=new $CLJS.M(null,"equal-to","equal-to",608296653),qoa=new $CLJS.M(null,"excludes","excludes",-1791725945),roa=new $CLJS.M(null,"not-equal-to","not-equal-to",
-343181655),soa=new $CLJS.M(null,"before","before",-1633692388);$CLJS.w2=function(){function a(d,e){return new $CLJS.h(null,3,[$CLJS.cu,$CLJS.YJ,$CLJS.iG,d,$CLJS.ZJ,e],null)}function b(d){return c.g?c.g(d,$CLJS.ci):c.call(null,d,$CLJS.ci)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.x2=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.w2.h($CLJS.Yr),$CLJS.w2.h($CLJS.HG),$CLJS.w2.h($CLJS.Rr),$CLJS.w2.h($CLJS.Vr),$CLJS.w2.h($CLJS.SF),$CLJS.w2.h($CLJS.Tr),$CLJS.w2.h($CLJS.Xr),$CLJS.w2.g($CLJS.RF,$CLJS.TF),$CLJS.w2.g($CLJS.bG,$CLJS.$F)],null);$CLJS.toa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.w2.h($CLJS.Yr),$CLJS.w2.h($CLJS.HG),$CLJS.w2.h($CLJS.TF),$CLJS.w2.h($CLJS.$F),$CLJS.w2.h($CLJS.oG),$CLJS.w2.h($CLJS.hG),$CLJS.w2.h($CLJS.gG),$CLJS.w2.h($CLJS.AG)],null);
$CLJS.uoa=new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.w2.g($CLJS.HG,qoa),$CLJS.w2.h($CLJS.Yr),$CLJS.w2.g($CLJS.Vr,soa),$CLJS.w2.g($CLJS.Rr,ooa),$CLJS.w2.h($CLJS.SF),$CLJS.w2.g($CLJS.RF,$CLJS.TF),$CLJS.w2.g($CLJS.bG,$CLJS.$F)],null);$CLJS.voa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.w2.h($CLJS.Yr),$CLJS.w2.h($CLJS.HG),$CLJS.w2.h($CLJS.YF),$CLJS.w2.h($CLJS.Rr),$CLJS.w2.h($CLJS.Vr),$CLJS.w2.h($CLJS.SF),$CLJS.w2.h($CLJS.Tr),$CLJS.w2.h($CLJS.Xr)],null);
$CLJS.woa=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.w2.g($CLJS.Yr,poa),$CLJS.w2.g($CLJS.HG,roa),$CLJS.w2.h($CLJS.Rr),$CLJS.w2.h($CLJS.Vr),$CLJS.w2.h($CLJS.SF),$CLJS.w2.h($CLJS.Tr),$CLJS.w2.h($CLJS.Xr),$CLJS.w2.g($CLJS.RF,$CLJS.TF),$CLJS.w2.g($CLJS.bG,$CLJS.$F)],null);
$CLJS.xoa=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.w2.h($CLJS.Yr),$CLJS.w2.h($CLJS.HG),$CLJS.w2.h($CLJS.oG),$CLJS.w2.h($CLJS.hG),$CLJS.w2.h($CLJS.RF),$CLJS.w2.h($CLJS.bG),$CLJS.w2.h($CLJS.TF),$CLJS.w2.h($CLJS.$F),$CLJS.w2.h($CLJS.gG),$CLJS.w2.h($CLJS.AG)],null);$CLJS.yoa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.w2.h($CLJS.Yr),$CLJS.w2.h($CLJS.HG),$CLJS.w2.h($CLJS.RF),$CLJS.w2.h($CLJS.bG),$CLJS.w2.h($CLJS.TF),$CLJS.w2.h($CLJS.$F)],null);
$CLJS.zoa=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.w2.h($CLJS.Yr),$CLJS.w2.g($CLJS.RF,$CLJS.TF),$CLJS.w2.g($CLJS.bG,$CLJS.$F)],null);$CLJS.Aoa=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.w2.h($CLJS.Yr),$CLJS.w2.h($CLJS.HG),$CLJS.w2.h($CLJS.RF),$CLJS.w2.h($CLJS.bG)],null);$CLJS.y2=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.R.j($CLJS.w2.h($CLJS.Yr),$CLJS.ci,!0),$CLJS.w2.h($CLJS.Rr),$CLJS.w2.h($CLJS.Vr),$CLJS.w2.h($CLJS.Tr),$CLJS.w2.h($CLJS.Xr),$CLJS.w2.h($CLJS.HG)],null);
$CLJS.I0.m(null,$CLJS.YJ,function(a,b,c,d){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.iG);b=$CLJS.J.g(b,$CLJS.ZJ);d=d instanceof $CLJS.M?d.T:null;switch(d){case "default":return v2(a,b);case "long":return u2(a,b);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}});
$CLJS.P0.m(null,$CLJS.YJ,function(a,b,c){c=$CLJS.O(c);a=$CLJS.J.g(c,$CLJS.iG);b=$CLJS.J.g(c,$CLJS.ZJ);c=$CLJS.J.g(c,$CLJS.ci);a=new $CLJS.h(null,3,[$CLJS.G0,$CLJS.bB(a),$CLJS.uE,v2(a,b),$CLJS.k0,u2(a,b)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.ci,!0):a});